import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class RootService {

  public _user: any = null;

  public _invitations: any = null;

  constructor() { }

  set user(user: any) {
    this._user = user;
  }

  get user(): any {
    return this._user;
  }

  set invitations(invitations: any) {
    this._invitations = invitations
  }

  get invitations(): any {
    return this._invitations;
  }
}
