import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-yes-no',
  templateUrl: './alert-yes-no.component.html',
  styleUrls: ['./alert-yes-no.component.scss']
})
export class AlertYesNoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any) {}

  ngOnInit() {
  }

}
