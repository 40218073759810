import { Injectable } from '@angular/core';
// const firebase = require("firebase");
import * as firebase from 'firebase';
// Required for side-effects
// require("firebase/functions");

@Injectable({
  providedIn: 'root'
})
export class AccessManagerService {

  constructor() { }
  
  grantAccess(email: string, role:string, access: string){
    var grantAccess = firebase.functions().httpsCallable('addAccess');
    grantAccess({email: email, role:role, access:access}).then(function(result) {
      
      console.log(result);
    });    
  }

  revokeAccess(email: string, role: string, access: string) {
    var revokeAccess = firebase.functions().httpsCallable('remAccess');
    revokeAccess({ email: email, role: role, access: access }).then(function (result) {

      console.log(result);
    });
  }

  createUser(email: string, role:string, access: any): Promise<any>{
    var createUser = firebase.functions().httpsCallable('createUser');
    return createUser({email: email, role:role, access:access})    
  }

  resetPassword(email:string, apiKey:string){
    var resetPassword = firebase.functions().httpsCallable(`resetPassword`);
    return resetPassword({email:email,apiKey:apiKey});
  }

  notify(apiKey: string, entityKey:string=null, text:string=null,selectedUsers=null,fileKey=null): Promise<any> {
    var checkViewedFilesRequest = firebase.functions().httpsCallable('notifyFile');
    console.log({
      apiKey: apiKey,
      entityKey: entityKey,
      text: text,
      users: selectedUsers,
      fileKey: fileKey
    })
    return checkViewedFilesRequest({ 
      apiKey: apiKey, 
      entityKey: entityKey, 
      text:text, 
      users:selectedUsers,
      fileKey:fileKey})
  }

  link(key:string): Promise<any>{
    var link = firebase.functions().httpsCallable('link');
    return link({key});
  }

}
