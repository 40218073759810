import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-get-message',
  templateUrl: './get-message.component.html',
  styleUrls: ['./get-message.component.scss']
})
export class GetMessageComponent implements OnInit {

  
  constructor(
    public dialogRef: MatDialogRef<GetMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
  onNoClick(): void {
    this.data = {message:null};
    this.dialogRef.close();
  }

}

