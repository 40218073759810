
import { Injectable } from '@angular/core'
import { FBServices } from './firebase.services'
import { HttpClient } from '@angular/common/http'

declare function require(name: string)
const CryptoJS = require('crypto-js')
const AES = CryptoJS.AES

@Injectable()

export class APPFunctions {

    constructor(
        private fbServices: FBServices,
        private httpClient: HttpClient,
    ) {

    }

    public soap(args) {
        return new Promise(resolve => {
            let params = this.encrypt(JSON.stringify(args))
            let wsdl = `https://overt-hcm.appspot.com/services/erp/params=${params},uid=${this.fbServices.DB.LS._uid}`
            this.httpClient.get(wsdl).subscribe((res) => {
                var result: any = res
                return resolve(result)
            })
        })
    }

    public async soapGravarMarcacao(args) {
        var params = this.encrypt(JSON.stringify(args))
        var wsdl = `https://overt-hcm.appspot.com/services/gravamarcacao/params=${params},uid=${this.fbServices.DB.LS._uid}`
        return await new Promise(resolve => {
            this.httpClient.get(wsdl).subscribe((res) => {
                resolve(res)
            })
        })
    }

    public importarCliente(cnpj: string, apiKey: string) {
        var wsdl = `https://overt-hcm.appspot.com/clients/${cnpj}/${apiKey}`
        return new Promise(resolve => {
            this.httpClient.get(wsdl).subscribe((res) => {
                resolve(res)
            })
        })
    }

    public encrypt(string: string) {
        try {
            if (this.fbServices.DB.LS._uid != undefined)
                return AES.encrypt(string, this.fbServices.DB.LS._uid).toString().replace(/\//g, '*')
        } catch (error) {

        }
    }

    public decrypt(string: string) {
        try {
            if (this.fbServices.DB.LS._uid != undefined)
                return AES.decrypt(string.replace(/\*/g, '/'), this.fbServices.DB.LS._uid).toString(CryptoJS.enc.Utf8)
        } catch (error) {

        }
    }

    public toCnpjId(string: string) {
        if (string) {
            return ("00000000000000" + string.toLowerCase().replace(/[^0-9]+/g, "")).slice(-14)
        } else {
            return null
        }
    }


    public isEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public toEmailId(string: string) {
        if (string) {
            return string.toLowerCase().replace(/\./g, "-").replace(/[^a-zA-Z0-9@]+/g, "-")
        } else {
            return null
        }
    }

    public toCpfId(string: string) {
        return ("00000000000" + string.toLowerCase().replace(/[^0-9]+/g, "")).slice(-11)
    }

    public getEmpresaAtiva() {
        try {
            if (this.fbServices.DB.LS.empresaAtiva != undefined) {
                let empresaAtiva = JSON.parse(this.decrypt(this.fbServices.DB.LS.empresaAtiva))
                empresaAtiva.cnpjContrato = this.toCnpjId(JSON.parse(this.decrypt(this.fbServices.DB.LS.empresaAtiva)).cnpj)
                return empresaAtiva
            } else {
                return null
            }
        } catch (error) {

        }
    }

    public getFontAwesomeIconFromMIME(mimeType) {
        // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
        var icon_classes = {
          // Media
          image: "fa-file-image-o",
          audio: "fa-file-audio-o",
          video: "fa-file-video-o",
          // Documents
          "application/pdf": "fa-file-pdf-o",
          "application/msword": "fa-file-word-o",
          "application/vnd.ms-word": "fa-file-word-o",
          "application/vnd.oasis.opendocument.text": "fa-file-word-o",
          "application/vnd.openxmlformats-officedocument.wordprocessingml":
            "fa-file-word-o",
          "application/vnd.ms-excel": "fa-file-excel-o",
          "application/vnd.openxmlformats-officedocument.spreadsheetml":
            "fa-file-excel-o",
          "application/vnd.oasis.opendocument.spreadsheet": "fa-file-excel-o",
          "application/vnd.ms-powerpoint": "fa-file-powerpoint-o",
          "application/vnd.openxmlformats-officedocument.presentationml":
            "fa-file-powerpoint-o",
          "application/vnd.oasis.opendocument.presentation": "fa-file-powerpoint-o",
          "text/plain": "fa-file-text-o",
          "text/html": "fa-file-code-o",
          "application/json": "fa-file-code-o",
          // Archives
          "application/gzip": "fa-file-archive-o",
          "application/zip": "fa-file-archive-o"
        };
      
        for (var key in icon_classes) {
          if (icon_classes.hasOwnProperty(key)) {
            if (mimeType.search(key) === 0) {
              // Found it
              return icon_classes[key];
            }
          } else {
            return "fa-file-o";
          }
        }
      }

}
