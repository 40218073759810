import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,    
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ],
 
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,

  ]
})
export class ComponentsModule { }
