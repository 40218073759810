import { RootService } from './root.service';
import { APPFunctions } from './app.functions';
import { FBServices } from './firebase.services';
import { Injectable, EventEmitter, Output, Input } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeyUser } from 'app/models/keyUser';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  public route = ''
  keyUser: KeyUser;
  @Input() invitations = new EventEmitter()
  constructor(
    public fbServices: FBServices,
    public router: Router,
    public func: APPFunctions,
    public rootService: RootService,
    private af: AngularFireDatabase
  ) {

  }

  async canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot)/*: Observable<boolean> | boolean*/ {
    let isAuthenticated: boolean
    await this.canLoad(state.url, route).then(result => {
      isAuthenticated = (result === true)
    })

    if (isAuthenticated !== true) {
      this.router.navigate(['/login'], {
        queryParams: {
          return: state.url
        }
      })
    }

    return isAuthenticated
  }

  canLoad(url: string, route: any) {
    return new Promise(resolve => {

      if (url === '/') {
        resolve(true)
      }

      this.fbServices.auth().onAuthStateChanged(user => {

        if (user) {
          if (!user.emailVerified) {
            return resolve(false)
          }

          this.rootService.user = {
            uid: user.uid,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            displayName: user.displayName,
            emailVerified: user.emailVerified,
            _token: user.refreshToken,
          }

          const ref_invitations = this.fbServices.DB.FB.ref('invitations').child('users').child(this.func.toEmailId(user.email))
          ref_invitations.on('value', invitations => {
            if (invitations.exists()) {
              if (invitations.val()) {
                this.rootService.user.invitations = []
                this.rootService.user.permissions = []
                Object.values(invitations.val()).forEach(cnpj => {
                  Object.values(cnpj).forEach(modulo => {
                    Object.values(modulo).forEach(invite => {
                      if (invite.convite_visualizado === false) {
                        this.rootService.user.invitations.push(invitations.val())
                      } else if (invite.convite_aceito === true) {
                        Object.keys(invite.permissions).map(key => {
                          this.rootService.user.permissions[key] = invite.permissions[key]
                        })
                      }
                    })
                  })
                })
              }
              this.invitations.emit(this.rootService.user.invitations)
            } else {
              this.rootService.user.invitations = []
            }
          })

          this.fbServices.DB.FB.ref('users').child(user.uid).child('http').child('empresa_ativa').once('value', empresa_ativa => {
            if (empresa_ativa.exists()) {
              this.rootService.user.empresa_ativa = empresa_ativa.val();
              if (empresa_ativa.val()._apiKey) {
                this.rootService.user.apiKey = empresa_ativa.val()._apiKey;
              }
            } else {// não existe empresa ativa
              this.rootService.user.empresa_ativa = null;
              this.rootService.user.apiKey = null;
            }
          })

          this.fbServices.DB.FB.ref('users').child(user.uid).child('empresa_logada').once('value', empresa_logada => {

            if (empresa_logada.exists()) {
              this.rootService.user.empresa_logada = empresa_logada.val();
              if (!this.rootService.user.apiKey) {
                this.fbServices.DB.FB.ref('_apiKey').child(empresa_logada.val().cnpjContratoTrabalho).once('value', apiKey => {
                  this.rootService.user.apiKey = apiKey.val()
                  return resolve(true)
                })
              }

            } else {// nenhuma empresa logada
              this.rootService.user.empresa_logada = null;
            }

            this.fbServices.DB.FB.ref('system').child('keyUser').child(user.uid).once('value', keyUser => {

              if (keyUser.exists()) {

                this.rootService.user.isKeyUser = true;
                this.rootService.user.customers = keyUser.val();
                return resolve(true)

              } else {

                this.rootService.user.isKeyUser = null;
                this.rootService.user.customers = null;

                if (empresa_logada.exists()) {
                  const fb_route = this.fbServices.DB.FB.ref('system').child('route').child('http')
                    .child(url).child(empresa_logada.val().cnpjContratoTrabalho).child(user.uid)
                  fb_route.once('value', auth => {
                    if (!auth.exists()) {
                      fb_route.update({
                        hasAccess: true
                      })
                      return resolve(false)
                    } else {
                      if (auth.val().hasAccess) {
                        return resolve(true)
                      } else {
                        return resolve(false)
                      }
                    }
                  })
                } else {
                  if (url.includes('services') || url.includes('invitations')) {
                    this.rootService.user.apiKey = null
                    
                    if (route.params) {
                      if (route.params.apiKey) {
                        this.fbServices.DB.FB.ref('_apiKey').child(route.params.apiKey).once('value', cnpj => {
                          if (cnpj.exists()) {
                            this.fbServices.DB.FB.ref('_apiKey').child(cnpj.val()).once('value', apiKey => {
                              this.rootService.user.apiKey = apiKey.val()
                              return resolve(true)
                            })
                          } else {
                            return resolve(false)
                          }
                        })
                      } else {
                        return resolve(false)
                      }
                    } else {
                      return resolve(false)
                    }
                  } else {
                    return resolve(false)
                  }
                }
              }
            })
          })
        } else {
          return resolve(false)
        }
      })
    })
  }
  /**
   * Retorna Usuário Logado
   */
  public getUser(): any {
    if (this.rootService.user) {
      return this.rootService.user;
    } else {
      return null
    }
  }

  public getInvitations(user: any) {
    if (user) {
      const ref_invitations = this.fbServices.DB.FB.ref('invitations').child('users').child(this.func.toEmailId(user.email))
      ref_invitations.on('value', invitations => {
        if (invitations.exists()) {
          this.rootService.invitations = invitations.val()
          this.invitations.emit(invitations.val())
        } else {
          this.rootService.invitations = null
        }
      })
    }
  }


  public saveKeyUser(uid:string,keyUser:KeyUser){
      this.af.list(`system/keyUser/${uid}`).set(keyUser.cnpj,keyUser);

  }

}
