import { Component, OnInit } from '@angular/core';
import { AccessManagerService } from 'app/services/access-manager.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ged-link',
  templateUrl: './ged-link.component.html',
  styleUrls: ['./ged-link.component.scss']
})
export class GedLinkComponent implements OnInit {
  public error:string;
  constructor (
    private accessManagerService: AccessManagerService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async (r: any) => {
      let key = r.params.key;
      var {data} = await this.accessManagerService.link(key);
      console.log(data);
      let {link,email,apiKey,error} = data;
      this.error = error;

      // if (email){
      //   window.localStorage.
      //   window.localStorage.setItem('emailForSignIn',email);
      // }
      if (link){        
        window.location.href = link;
      }
        
    })

  }

}
