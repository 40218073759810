import { Injectable } from '@angular/core';
import { AuthGuardService } from 'app/services/auth-guard.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';



@Injectable({
  providedIn: 'root'
})
export class SocialService {

  user: any

  constructor(
    private af: AngularFireDatabase,
    private auth: AuthGuardService
  ) {
    this.user = this.auth.getUser()
  }


  public getGroups(): AngularFireList<any> {
    return this.af.list(`social/${this.user.apiKey.apiKey}/groups`);
  }
}
