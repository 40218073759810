import { SocialService } from './../../services/social.service';
import { AuthGuardService } from 'app/services/auth-guard.service';
import { FBServices } from '../../services/firebase.services';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as moment from 'moment'
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

class MyDataSource<T> extends DataSource<T> {
  public filter;
  public paginator: MatPaginator
  public sort: MatSort
  constructor(private observable: Observable<T[]>) {
    super();
  }
  connect() {
    return this.observable;
  }
  disconnect() { }

}

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})

export class SocialComponent implements OnInit {


  IsShowGroups: boolean = false

  imageChangedEvent: any = '';
  croppedImage: any = null;
  dataSource: MatTableDataSource<{}>;
  subscription: Subscription;

  createGroupForm: FormGroup = new FormGroup({
    group_name: new FormControl('', [Validators.required]),
    group_resume: new FormControl('', [Validators.required]),
    group_description: new FormControl('', [Validators.required]),
    // group_isPublic: new FormControl('', [Validators.required]),
  })

  groups: any
  groups_in: any
  groups_of_mine: any
  group: any = null
  user: any
  group_name: string;
  group_resume: string;
  group_description: string;
  group_isPublic: boolean = false

  constructor(
    public fbServices: FBServices,
    public auth: AuthGuardService,
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private af: AngularFireDatabase,
    private socialService: SocialService
  ) {
    this.user = this.auth.getUser()
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  fnGruposQueParticipo(group) {
    console.log(this.groups)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    // console.log(this.croppedImage)
    // var croppedImage: any = document.getElementById('croppedImage')
    // croppedImage.src = event.file
  }

  fnSetGroup(group) {
    this.group = group
    console.log(this.group)

  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  fnInscrever(group) {
    this.fbServices.DB.FB.ref('social').child(this.user.apiKey.apiKey)
      .child('groups').child(group.id).child('requests').child(this.user.uid)
      .set(group.isPublic)
  }


  fnCriarGrupo() {
    var YYYYMMDDHHmmss = moment().format('YYYYMMDDHHmmss')
    this.fbServices.DB.FB.ref('social').child(this.user.apiKey.apiKey).child('groups').child(YYYYMMDDHHmmss).update({
      id: YYYYMMDDHHmmss,
      img: this.croppedImage,
      owner: this.user.email,
      name: this.group_name,
      isPublic: this.group_isPublic,
      resume: this.group_resume,
      description: this.group_description,
      requests: {
        [this.user.uid]: true
      }
    }).catch(e => {
      console.log(e)
    })
  }


  fnLogout() {
    this.fbServices.logout()
  }

  fnNovaPublicacao(e) {
    console.log(e)
  }

  fnIsShowGroups() {
    this.IsShowGroups = !this.IsShowGroups
    console.log(this.IsShowGroups)
  }

  getPermissions(): AngularFireList<any> {
    return this.af.list(`invitations/permissions/${this.user.uid}`);
  }

  ngOnInit() {

    this.user = this.auth.getUser();

    console.log(this.user)

    // this.subscription = this.getPermissions().valueChanges().subscribe(permissions => {
    //   console.log(permissions)
    // });

    /**
     * Permite o Edge carregar imagem
     */
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          var canvas = this;
          setTimeout(function () {
            var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
              len = binStr.length,
              arr = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            callback(new Blob([arr], { type: type || 'image/png' }));
          });
        }
      });
    }

    this.subscription = this.socialService.getGroups().valueChanges().subscribe(groups => {
      this.groups = groups
      this.groups_in = []
      this.groups_of_mine = []
      this.groups.forEach(group => {
        if (group.requests) {
          if (group.requests[this.user.uid]) {
            this.groups_in.push(group);
          }
        }
        if (group.owner == this.user.email) {
          this.groups_of_mine.push(group);
        }
      })
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
