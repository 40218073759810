import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-get-message-users',
  templateUrl: './ged-message-users.component.html',
  styleUrls: ['./ged-message-users.component.scss']
})
export class GedMessageUserComponent implements OnInit {

  
  constructor(
    public dialogRef: MatDialogRef<GedMessageUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
     }

  ngOnInit() {
  }
  onNoClick(): void {
    this.data = {message:null};
    this.dialogRef.close();
  }

}

